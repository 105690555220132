import Link from "next/link";

import Icon from "../Icon";

import {
  Container,
  Wrapper,
  Block,
  Title,
  Item,
  LinkItem,
  IconsItem,
  SocialMedias,
  CopyrightBlock,
} from "./styles";
import dayjs from "dayjs";

const DRAFT5_SOCIAL_MEDIAS = [
  {
    id: "facebook",
    icon: "facebook",
    url: "https://facebook.com/DRAFT5gg/",
  },
  {
    id: "instagram",
    icon: "instagram",
    url: "https://instagram.com/draft5gg/",
  },
  {
    id: "twitter",
    icon: "twitterx",
    url: "https://x.com/draft5gg",
  },
  {
    id: "youtube",
    icon: "youtube",
    url: "https://youtube.com/gamersclubmediatv?sub_confirmation=1",
  },
  {
    id: "tiktok",
    icon: "tiktok",
    url: "https://tiktok.com/@gamersclubmedia",
  },
  {
    id: "threads",
    icon: "threads",
    url: "https://threads.net/@draft5gg",
  },
  {
    id: "bluesky",
    icon: "bluesky",
    url: "https://bsky.app/profile/draft5.gg",
  },
];

export function Footer() {
  return (
    <Container>
      <Wrapper>
        <Block>
          <Title>Contato</Title>
          <LinkItem href="mailto:press@draft5.gg">
            Imprensa: press@draft5.gg
          </LinkItem>
          <LinkItem href="mailto:contato@draft5.gg">
            Contato: contato@draft5.gg
          </LinkItem>
          <LinkItem href="mailto:comercial@draft5.gg">
            Comercial: comercial@draft5.gg
          </LinkItem>
        </Block>

        <Block>
          <Title>Endereço</Title>
          <Item>Rua Maria Soares Leitão, 112</Item>
          <Item>Sorocaba/SP</Item>
          <Item>18047-690</Item>
          <Item>Brasil</Item>
        </Block>

        <Block>
          <Title>Útil</Title>
          <LinkItem href="https://forms.gle/fc1ZWVwfvGxiin2m9" target="_blank">
            Atualizar infos equipe
          </LinkItem>
          <LinkItem href="https://forms.gle/h39mS556fRzMrw9z8" target="_blank">
            Adicionar livestream na partida
          </LinkItem>
        </Block>

        <Block>
          <Title>Links</Title>
          <Link prefetch={false} href={"/sobre-nos"} passHref>
            <LinkItem>Sobre nós</LinkItem>
          </Link>
          <Link prefetch={false} href={"/disclosure"} passHref>
            <LinkItem>Avisos importantes</LinkItem>
          </Link>
          <LinkItem
            href="https://gamersclub.com.br/terms-of-use"
            target="_blank"
          >
            Termos de uso
          </LinkItem>
          <LinkItem
            href="https://gamersclub.com.br/privacy-policy"
            target="_blank"
          >
            Política de privacidade
          </LinkItem>
          {/* <LinkItem href="https://gamersclub.com.br/rules" target="_blank">
            Regras de conduta
          </LinkItem> */}
          <LinkItem href="https://siprocal.gupy.io/" target="_blank">
            Trabalhe com a gente
          </LinkItem>
        </Block>

        <Block>
          <SocialMedias>
            {DRAFT5_SOCIAL_MEDIAS.map((socialMedia) => (
              <a
                key={`${socialMedia.id}`}
                href={socialMedia.url}
                target="_blank"
              >
                <Icon
                  name={socialMedia.icon}
                  size={socialMedia.id === "tiktok" ? 16 : 20}
                />
              </a>
            ))}
          </SocialMedias>
          <IconsItem>
            <Icon name="draft5" width={45} />
            é um produto
            <Icon name="gamersclub" width={100} viewBox="0 0 104 16" />
          </IconsItem>
          <CopyrightBlock>
            © 2017-{dayjs().year()} • Todos os direitos reservados
          </CopyrightBlock>
        </Block>
      </Wrapper>
    </Container>
  );
}
